<template>
  <div class="route-preloader">
    <div>
      <img src="/img/preloader_logo.svg" >
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>