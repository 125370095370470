<template>
  <div
    class="video_modal"
    ref="modalWindow"
  >
    <div
      @click="closeModal"
      class="close"
    >
      <i class="fas fa-times"></i>
    </div>
    <div
      class="bookmark-action"
      v-if="bookmarkAction"
    >
      <base-bookmark-icon
        :isBookmarked="isBookmarkedData"
        :big="true"
        @addToBookmarks="addToBookmarks"
      />
    </div>
    <div
      class="video-title"
      :class="{ show: showTitle }"
    >
      {{ videoTitle }}
    </div>
    <video
      ref="videoPlayer"
      class="video-js"
    ></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import MobileWidthMixin from '../../mixins/MobileWidthMixin';

export default {
  data: function() {
    return {
      playerOptions: {
        // width: '1024px',
        sources: [
          {
            type: 'application/x-mpegurl',
            src: this.videoUrl,
            withCredentials: false
          }
        ],
        // height: '614px',
        // fluid: true,
        responsive: true,
        controls: true,
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
        poster: this.thumbnail,
        responsive: true,
        name: 'player'
      },
      isBookmarkedData: this.isBookmarked,
      showTitle: true,
      timeToSetWatched: null
    };
  },
  props: {
    thumbnail: { type: String, default: '' },
    videoUrl: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    percentToSetWatched: { type: Number, default: 95 },
    bookmarkAction: { type: Boolean, default: false },
    isBookmarked: { type: Boolean, default: false },
    addToBookmarkFunction: { type: Function, default: () => {} },
    setWatchedFunction: { type: Function, default: () => {} }, // this modal doesn't allow to emit custom events so this functions passed here as props
    onWatched: { type: Function }
  },
  mixins: [MobileWidthMixin],
  computed: {
    videoTitle() {
      return this.subtitle ? `${this.title}: ${this.subtitle}` : this.title;
    }
  },
  mounted() {
    const videoHeight = this.$refs.modalWindow.clientWidth / (16 / 9);
    this.playerOptions.height = videoHeight;
    this.player = videojs(this.$refs.videoPlayer, this.playerOptions, () => {
      this.player.on('useractive', () => {
        this.showTitle = true;
      });
      this.player.on('userinactive', () => {
        this.showTitle = false;
      });
      this.player.on('loadeddata', () => {
        this.timeToSetWatched =
          (this.player.duration() * this.percentToSetWatched) / 100;
      });
      this.player.on('timeupdate', () => {
        if (this.player.currentTime() > this.timeToSetWatched) {
          this.setWatchedFunction();
          this.player.off('timeupdate');
        }
      });
      this.player.on('ended', () => {
        this.onWatched && this.onWatched();
        this.$emit('watched');
      });
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    addToBookmarks() {
      this.addToBookmarkFunction();
      this.isBookmarkedData = !this.isBookmarkedData;
    }
  }
};
</script>
