<template>
  <div :class="className" style="position: relative">
    <div class="block__video" :style="avatarSize">
      <div
        class="video"
        v-if="mutatedUrl !== null"
        v-bind:style="{ backgroundImage: 'url(' + mutatedUrl + ')' }"
      >
        <img
          class="video_icon"
          v-if="showPlayButtom !== false"
          @click="playVideo"
          src="/img/icons/play_video.svg"
        />
      </div>

      <div
        class="video"
        v-else
        v-bind:style="{ backgroundImage: 'url(' + url + ')' }"
      >
        <img
          class="video_icon"
          v-if="showPlayButtom !== false"
          @click="playVideo"
          src="/img/icons/play_video.svg"
        />
      </div>

      <div v-if="haveUploadPhoto" @click="triggerPhoto">
        <i class="fas fa-camera icon" />
      </div>

      <div
        class="delete-video"
        v-if="mutatedUrl && haveUpload && deleteImgBtn"
        @click="deleteImage"
      >
        <i class="fa fa-trash" aria-hidden="true"></i>
      </div>

      <div
        class="delete-item"
        v-if="$listeners.deleteItem && showDeleteItemButton"
        style="position: absolute; bottom: -14px; right: -14px; cursor: pointer;"
      >
        <img
          @click="$emit('deleteItem')"
          src="/img/icons/stop_colored.svg"
          class="delete-item-icon"
        />
      </div>
    </div>
    <div class="change-video" v-if="haveUpload">
      <label @click="trigger" v-if="!hideUploadLabel">Upload Video</label>
      <input
        type="file"
        accept=".mp4,.mov,.flv"
        ref="fileInput"
        @change="uploadFile"
      />
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        ref="photoInput"
        @change="uploadThumbnail"
      />
    </div>

    <div class="change-moves-group">
      <div class="change-wrap" @click="triggerPhotoOnly">
        <slot name="change-thumbnail"></slot>
      </div>
      <div class="change-wrap" @click="trigger">
        <slot name="changevideo"></slot>
      </div>
    </div>

    <div class="video-demo-container">
      <input type="file" class="file-to-upload" accept="video/mp4" />

      <video class="main-video" controls ref="mainVideo">
        <source type="video/mp4" />
      </video>
      <canvas class="video-canvas" ref="canvasVideo"></canvas>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getFileFromURL } from '../../helpers';
import BaseVideoPlayerModal from './BaseVideoPlayerModal';
import { store } from '../../store';

export default {
  data: function() {
    return {
      /** @var {String} mutatedUrl. Example: 'data:image/png;base64,...' */
      mutatedUrl: this.url,
      /** @var {File|null} video */
      video: null,
      photo: null, // @note Явно выбранное изображение
      videoPath: '',
      videoRelativePath: '',
      photoOnly: false
    };
  },
  props: {
    url: { type: String, default: null },
    videoUrl: { type: String, default: null },
    haveUploadPhoto: { type: Boolean, default: true },
    haveUpload: { type: Boolean, default: false },
    showPlayButtom: { type: Boolean, default: false },
    deleteImgBtn: { type: Boolean, default: true },
    width: { type: String, default: '42px' },
    height: { type: String, default: '42px' },
    className: { type: String, default: 'avatar' },
    uploadVideoEventName: { type: String, default: 'uploadvideo' },
    type: { type: String, default: 'recordings' },
    hideUploadLabel: { type: Boolean, default: false },
    showDeleteItemButton: { type: Boolean, default: false }
  },
  computed: {
    avatarSize() {
      return {
        width: this.width,
        height: this.height
      };
    }
  },
  destroyed() {
    this.$off('removeloadedmetadata');
  },
  watch: {
    url: function(val) {
      this.mutatedUrl = val;
    }
  },
  methods: {
    ...mapActions('account', ['deletePhoto']),
    ...mapActions('classes', ['generateVideoS3']),
    ...mapActions({
      errorAlert: 'alert/error'
    }),
    deleteImage(e) {
      if (this.mutatedUrl) {
        this.mutatedUrl = '';
        this.photo = '';
        this.$refs.photoInput.value = '';
      }
    },
    uploadFile(e) {
      let timestamp = +new Date();

      this.video = this.$refs.fileInput.files[0];
      this.videoPath = this.type + '/' + timestamp;
      this.videoRelativePath =
        this.videoPath +
        '/' +
        this.video.name.replace(/[^A-Za-z0-9\-\/.]/g, '');

      store.commit('ui/startRouteLoading', null, { root: true });
      this.generateVideoS3(this.videoRelativePath)
        .then((videoUploadUrl) => {
          this.uploadVideo(videoUploadUrl);
        })
        .catch((err) => {
          console.log('Error in BaseVideoPlayer: generateVideoS3:', err);
          store.commit('ui/endRouteLoading', null, { root: true });
        });
    },
    uploadThumbnail(e) {
      this.photo = this.$refs.photoInput.files[0];
      this.mutatedUrl = URL.createObjectURL(this.photo);
      if (this.photoOnly) {
        const reader = new FileReader();
        reader.readAsDataURL(this.photo);
        reader.onloadend = () => {
          const source = reader.result;
          let uploadParams = {
            thumbnailFile: this.photo,
            thumbnailBase64: source
          };
          this.$root.$emit(this.uploadVideoEventName, uploadParams);
        };
      }
      // blob:http://admin.skydance.lcl:9008/11b316e8-34a6-4725-926c-af7b05a258d9
    },
    trigger() {
      this.$refs.fileInput.click();
    },
    triggerPhoto() {
      this.$refs.photoInput.click();
    },
    triggerPhotoOnly() {
      this.photoOnly = true;
      this.$refs.photoInput.click();
    },
    playVideo() {
      if (!this.videoUrl) {
        this.errorAlert('Video is not yet converted');
        return;
      }

      this.$modal.show(
        BaseVideoPlayerModal,
        {
          thumbnail: this.mutatedUrl,
          videoUrl: this.videoUrl,
          onWatched: () => {
            this.$emit('watched');
          }
        },
        {
          width: 1024,
          height: 'auto',
          classes: [
            // 'add-record-modal'
          ],
          scrollable: true,
          clickToClose: false
        }
      );
    },
    uploadVideo(videoUploadUrl) {
      let canvasNode = this.$refs.canvasVideo,
        canvasCtx = canvasNode.getContext('2d'),
        videoNode = this.$refs.mainVideo;

      if (
        ['video/mp4', 'video/quicktime', 'video/x-flv'].indexOf(
          this.video.type
        ) === -1
      ) {
        console.log('Error : Only MP4 or MOV format allowed');
        return;
      }

      videoNode
        .querySelector('source')
        .setAttribute('src', URL.createObjectURL(this.video));
      videoNode.load();

      let onLoadedVideoHandler = () => {
        /** @var {Float} duration. Example: 5.803 */
        const duration = videoNode.duration;
        const width = 400;
        const proportion = videoNode.videoWidth / width;
        const height = videoNode.videoHeight / proportion;

        canvasNode.width = width;
        canvasNode.height = height;

        // console.log(
        //   'width, height before',
        //   videoNode.videoWidth,
        //   videoNode.videoHeight
        // );

        // console.log('width, height after', width, height);

        // canvasNode.width = videoNode.videoWidth / 5;
        // canvasNode.height = videoNode.videoHeight / 5;
        videoNode.currentTime = 1;

        setTimeout(async () => {
          canvasCtx.drawImage(videoNode, 0, 0, width, height);

          // canvasCtx.drawImage(videoNode, 0, 0, videoNode.videoWidth / 5, videoNode.videoHeight / 5);

          if (this.url || this.photo) {
            let uploadParams = {
              thumbnailFile: this.photo ? this.photo : '',
              //thumbnailBase64: '', // @todo
              link: videoUploadUrl,
              uploadUrl: videoUploadUrl,
              uploadFile: this.video,
              relativePath: this.videoRelativePath
            };
            this.$root.$emit(this.uploadVideoEventName, uploadParams);
            this.$emit('removeloadedmetadata', {});
            return;
          }

          this.mutatedUrl = canvasNode.toDataURL(); // base64 encoded

          getFileFromURL(this.mutatedUrl, this.makeAutoThumbnailName()).then(
            (res) => {
              this.photo = res;
              // store.commit('ui/endRouteLoading', null, { root: true });

              let uploadParams = {
                thumbnailFile: res,
                thumbnailBase64: this.mutatedUrl,
                link: videoUploadUrl,
                uploadUrl: videoUploadUrl,
                uploadFile: this.video,
                relativePath: this.videoRelativePath
              };
              this.$root.$emit(this.uploadVideoEventName, uploadParams);
              this.$emit('removeloadedmetadata', {});
            }
          );
        }, 1500);
      };

      this.$on('removeloadedmetadata', async (data) => {
        videoNode.removeEventListener('loadedmetadata', onLoadedVideoHandler);
      });

      videoNode.addEventListener('loadedmetadata', onLoadedVideoHandler);
    },
    makeAutoThumbnailName() {
      let videoName = this.video.name.split('.')[0];
      return videoName + '.png';
    }
  }
};
</script>

<style scoped lang="scss">
.video {
  border-radius: 15px !important;
  background-color: #382f49;
}

.fa-camera.icon {
  z-index: 0;
  color: #ffffff;
  left: 92%;
  top: 87%;
  width: 15%;
  height: 10%;
}
.change-moves-group {
  // display: flex;
  // position: absolute;
  // top: 20px;
  // left: 15px;
}
.change-wrap {
  position: absolute;
  // width: 35px;
  // height: 35px;
  font-size: 30px;
  cursor: pointer;
  color: #6b5a82;
  &:first-child {
    margin-right: 10px;
    top: 40px;
    left: 15px;
  }
  &:last-child {
    top: 10px;
    right: 10px;
  }
  &:hover {
    color: #5d4e70;
  }
}
</style>
