import { liveClassesService, recClassesService } from '../services';
import { router, validateFields } from '../helpers';
// import moment from 'moment';
import { store } from './index';

let secrets = JSON.parse(localStorage.getItem('secrets')) || {};

const state = {
  secrets,
  loading: false,
  pageNumber: 1,
  weekNumber: 1,
  instructorIds: [],
  classTypeIds: [],
  categoriesIds: [],
  liveClassCourses: null,
  liveClassCoursesTotal: null,
  liveClassCourse: null,
  liveClass: null,
  hasLive: false,
  categories: [],
  upcomingLiveClasses: [],
  recordedClasses: []
};

const actions = {
  findLiveClassCoursesParams({ state, commit }, { levelId, teacherId, categoryId, page = 1 }) {
    let params = {
      page: page
    };

    if (!levelId && !teacherId && !categoryId) {
      params.live_and_future = 1;
    } else {
      params = Object.assign(params, {
        archive_only: 1,
        category_id: [categoryId]
      });

      if (levelId) {
        params.level_id = [levelId];
      }

      if (teacherId) {
        params.instructor_id = [teacherId];
      }
    }

    return liveClassesService
      .findLiveClassCourses(params)
      .then((result) => {
        commit('setLiveClassCourses', result.live_classes);
        commit('setLiveClassCoursesTotal', result.total);
        return { 
          courses: result.live_classes, 
          total: result.total
        };
      });
  },
  findLiveClassCourses({ state, commit }, exclude = 0) {
    return liveClassesService
      .findLiveClassCourses({
        page: state.pageNumber,
        instructor_id: state.instructorIds,
        class_type_id: state.classTypeIds,
        category_id: state.categoriesIds,
        exclude_completed: exclude
      })
      .then((result) => {
        commit('setLiveClassCourses', result.live_classes);
        commit('setLiveClassCoursesTotal', result.total);
        return { courses: result.live_classes, total: result.total };
      });
  },
  getClassesInstructors({ state, commit }, categoryId) {
    return liveClassesService
      .getClassesInstructors(categoryId)
      .then((result) => {
        return result.result.instructors;
      });
  },
  fetchLiveClassCourse({ commit, dispatch }, { courseId, search, isLiveMenu, secret }) {
    return liveClassesService
      .fetchLiveClassCourse({ courseId, search, isLiveMenu, secret })
      .then((course) => {
        commit('setLiveClassCourse', course);
        return course;
      })
      .catch((error) => {
        if (error.response.status === 403 && error.response.data.message) {
          dispatch('alert/error', error.response.data.message, { root: true });
          Promise(reject);
        }
      });
  },
  fetchLiveClass({ commit }, { courseId, liveClassId, secret }) {
    return liveClassesService
      .fetchLiveClass({
        courseId,
        liveClassId,
        secret
      })
      .then((curClass) => {
        commit('setLiveClass', curClass);
      });
  },
  secretValidate(context, data) {
    return liveClassesService
      .secretValidate(data)
      .then((response) => response.data.result.valid);
  },
  createLiveClassCourse({ commit }, data) {
    commit('ui/startLoading', null, { root: true });
    return liveClassesService
      .createLiveClassCourse(data)
      .then((response) => {
        router.push({
          name: 'adminLiveClassCourse',
          params: {
            courseId: response.data.result.live_class_id
          }
        });
      })
      .catch(
        (error) => error.response && validateFields(error.response.data.errors)
      )
      .finally(() => {
        commit('ui/endLoading', null, { root: true });
      });
  },
  createLiveClass({ commit }, { courseId, data }) {
    commit('ui/startLoading', null, { root: true });
    liveClassesService
      .createLiveClass({ courseId, data })
      .then(async () => {
        router.go(-1);
      })
      .catch(
        (error) => error.response && validateFields(error.response.data.errors)
      )
      .finally(() => {
        commit('ui/endLoading', null, { root: true });
      });
  },
  editLiveClassCourse({ commit }, { courseId, data }) {
    commit('ui/startLoading', null, { root: true });
    liveClassesService
      .editLiveClassCourse({ courseId, data })
      .then(() => {
        router.push({
          name: 'adminLiveClassCourses'
        });
      })
      .catch(
        (error) => error.response && validateFields(error.response.data.errors)
      )
      .finally(() => {
        commit('ui/endLoading', null, { root: true });
      });
  },
  editLiveClass({ commit }, { courseId, data, liveClassId }) {
    commit('ui/startLoading', null, { root: true });
    liveClassesService
      .editLiveClass({ courseId, data, liveClassId })
      .then(async () => {
        router.go(-1);
      })
      .catch(
        (error) => error.response && validateFields(error.response.data.errors)
      )
      .finally(() => {
        commit('ui/endLoading', null, { root: true });
      });
  },
  removeLiveClassCourse({ commit, dispatch }, { courseId }) {
    commit('ui/startRouteLoading', null, { root: true });
    return liveClassesService
      .removeLiveClassCourse({ courseId })
      .then(async () => {
        dispatch('alert/success', 'Live class was removed', { root: true });
        await dispatch('findLiveClassCourses');
      })
      .finally(() => {
        commit('ui/endRouteLoading', null, { root: true });
      });
  },
  removeLiveClass({ commit, dispatch }, { courseId, liveClassId, secret }) {
    commit('ui/startRouteLoading', null, { root: true });
    return liveClassesService
      .removeLiveClass({ courseId, liveClassId })
      .then(async () => {
        dispatch('alert/success', 'Live video was removed', { root: true });
        await dispatch('fetchLiveClassCourse', { courseId, secret });
      })
      .finally(() => {
        commit('ui/endRouteLoading', null, { root: true });
      });
  },
  deactivateLiveClassCourse({ commit, dispatch }, { courseId, deactivated }) {
    const msg = deactivated
      ? 'Live class course was deactivated'
      : 'Live class course was activated';
    commit('ui/startRouteLoading', null, { root: true });
    return liveClassesService
      .deactivateLiveClassCourse({ courseId, deactivated })
      .then(() => {
        commit('setCourseDeactivated', courseId);
        dispatch('alert/success', msg, { root: true });
      })
      .catch(() => {
        dispatch('alert/error', 'Something went wrong', { root: true });
      })
      .finally(() => {
        commit('ui/endRouteLoading', null, { root: true });
      });
  },
  deactivateLiveClass(
    { commit, dispatch },
    { courseId, liveClassId, deactivated }
  ) {
    const msg = deactivated
      ? 'Live class was deactivated'
      : 'Live class was activated';
    commit('ui/startRouteLoading', null, { root: true });
    return liveClassesService
      .deactivateLiveClass({
        courseId,
        liveClassId,
        deactivated
      })
      .then(() => {
        commit('setDeactivated', liveClassId);
        dispatch('alert/success', msg, { root: true });
      })
      .catch(() => {
        dispatch('alert/error', 'Something went wrong', { root: true });
      })
      .finally(() => {
        commit('ui/endRouteLoading', null, { root: true });
      });
  },
  addLiveToBookmarks({ state, commit, dispatch }, { id, status }) {
    const msg = status
      ? 'Live class was added to bookmarks'
      : 'Live class was removed from bookmarks';
    liveClassesService
      .addToBookmarks({ type: 'live_class_video', id, status })
      .then(() => {
        state.liveClassCourse && commit('setBookmarked', id);
        dispatch('alert/success', msg, { root: true });
      });
  },
  generateVideoS3({ dispatch, commit }, videoRelativePath) {
    return recClassesService
      .generateVideoS3({ filename: videoRelativePath })
      .then((data) => {
        return data.result.link;
      })
      .catch(function(error) {
        dispatch('alert/error', error, { root: true });
        throw error;
      });
  },
  uploadVideo({ dispatch, commit }, { url, file }) {
    return new Promise(function(resolve, reject) {
      let oReq = new XMLHttpRequest();
      oReq.open('PUT', url, true);
      oReq.onload = function(oEvent) {
        store.commit('ui/endRouteLoading', null, { root: true });
        dispatch('alert/success', 'Video has been upload', { root: true });
        resolve(`${file.name}`);
      };
      oReq.send(file);
    });
  },
  add(
    { dispatch, commit },
    {
      title,
      description,
      level,
      class_type_id,
      instructor,
      category,
      photo,
      videoAwsUrl
    }
  ) {
    return recClassesService
      .add({
        title,
        description,
        level,
        class_type_id,
        instructor,
        category,
        photo,
        videoAwsUrl
      })
      .then((data) => {
        dispatch('alert/success', 'Record was added', { root: true });
        console.log('aws link was generated succesully');

        return data;
      })
      .catch(function(error) {
        dispatch('alert/error', error, { root: true });
        throw error;
      });
  },
  updateClass(
    { dispatch, commit },
    {
      classId,
      title,
      description,
      level,
      class_type_id,
      instructor,
      category,
      photo,
      video,
      moves
    }
  ) {
    return recClassesService
      .updateClass(classId, {
        title,
        description,
        level,
        class_type_id,
        instructor,
        category,
        photo,
        video,
        moves
      })
      .then((data) => {
        dispatch('alert/success', 'Record was updated', { root: true });
        return data;
      })
      .catch(function(error) {
        dispatch('alert/error', error, { root: true });
        throw error;
      });
  },
  getClassesCategories({ commit }) {
    return recClassesService.getClassesCategories().then((data) => {
      commit('setCategories', data.result.categories);
      return true;
    });
  },
  getClass({ commit }, id) {
    return recClassesService.getClass(id).then((data) => {
      return data.result.class;
    });
  },
  getUpcomingLiveClasses({ commit }) {
    return recClassesService.getUpcomingLiveClasses().then((data) => {
      commit('setUpcomingLiveClasses', data.result.live_classes);
    });
  },
  fetchRecordedClasses({ state, commit }, { id, page = 1, filters }) {
    return recClassesService
      .fetchRecordedClasses(id, page, filters)
      .then((result) => {
        commit('setRecordedClasses', result);
        return result;
      });
  },
  deactivateOrActivate({ dispatch, commit }, id) {
    return recClassesService.deactivateOrActivate(id).then(
      async (success) => {
        dispatch('alert/success', 'Class was deactivated or activated', {
          root: true
        });
      },
      (error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      }
    );
  },
  deleteMove({ dispatch, commit }, id) {
    return recClassesService.deleteMove(id).then(
      async (success) => {
        dispatch('alert/success', 'Move was deleted', { root: true });
      },
      (error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      }
    );
  },
  delete({ dispatch, commit }, id) {
    return recClassesService.delete(id).then(
      async (success) => {
        dispatch('alert/success', 'Class was deleted', { root: true });
      },
      (error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      }
    );
  },
  /** @deprecated */
  publish({ dispatch, commit }, id) {
    return recClassesService.publish(id).then(
      async (success) => {
        dispatch('alert/success', 'Class was publish', { root: true });
      },
      (error) => {
        dispatch('alert/error', error, { root: true });
        throw error;
      }
    );
  },
  updateMove({ dispatch, commit }, { moveId, thumbnail, title, order }) {
    return recClassesService
      .updateMove(moveId, { thumbnail, title, order })
      .then(
        async (success) => {
          dispatch('alert/success', 'Move was updated', { root: true });
        },
        (error) => {
          dispatch('alert/error', error, { root: true });
          throw error;
        }
      );
  },
  swapOrder(
    { dispatch, commit },
    { moveOneId, moveTwoId, orderOne, orderTwo }
  ) {
    return recClassesService
      .swapOrder({ moveOneId, moveTwoId, orderOne, orderTwo })
      .then(
        async (success) => {
          dispatch('alert/success', 'Order was changed', { root: true });
        },
        (error) => {
          dispatch('alert/error', error, { root: true });
          throw error;
        }
      );
  }
};
const mutations = {
  // setCategories(state, categories) {
  //   state.categories = categories;
  // },
  setUpcomingLiveClasses(state, classes) {
    state.upcomingLiveClasses = classes;
  },
  setRecordedClasses(state, data) {
    state.recordedClasses = {
      items: data.result.recorded_classes,
      lastPage: data.result.meta.last_page,
      perPage: data.result.meta.per_page,
      currentPage: data.result.meta.current_page
    };
  },
  clearRecordedClasses(state) {
    state.recordedClasses = {};
  },
  setLiveClassCourses(state, courses) {
    state.liveClassCourses = courses;
  },
  setLiveClassCoursesTotal(state, total) {
    state.liveClassCoursesTotal = total;
  },
  setLiveClassCourse(state, course) {
    state.liveClassCourse = course;
  },
  setWeek(state, week) {
    state.weekNumber = week ? week : 1;
  },
  setLiveClass(state, curClass) {
    state.liveClass = curClass;
  },
  setEmptyLiveClassCourse(state) {
    state.liveClassCourse = null;
  },
  setEmptyLiveClass(state) {
    state.liveClass = null;
  },
  setSecret(state, { courseId, secret }) {
    state.secrets[courseId] = secret;
    localStorage.setItem('secrets', JSON.stringify(state.secrets));
  },
  checkInstructor(state, id) {
    const index = state.instructorIds.indexOf(id);
    index !== -1
      ? state.instructorIds.splice(index, 1)
      : state.instructorIds.push(id);
  },
  checkClassType(state, id) {
    const index = state.classTypeIds.indexOf(id);
    index !== -1
      ? state.classTypeIds.splice(index, 1)
      : state.classTypeIds.push(id);
  },
  checkCategory(state, id) {
    const index = state.categoriesIds.indexOf(id);
    index !== -1
      ? state.categoriesIds.splice(index, 1)
      : state.categoriesIds.push(id);
  },
  // checkDiffLevel(state, id) {
  //   const index = state.difficultyLevelIds.indexOf(id);
  //   index !== -1
  //     ? state.difficultyLevelIds.splice(index, 1)
  //     : state.difficultyLevelIds.push(id);
  // },
  checkClassTypes(state, ids) {
    state.classTypeIds = ids;
  },
  checkCategories(state, ids) {
    state.categoriesIds = ids;
  },
  // checkDiffLevels(state, ids) {
  //   state.difficultyLevelIds = ids;
  // },
  setPageNumber(state, number) {
    state.pageNumber = number;
  },
  resetCheckedInstrs(state) {
    state.instructorIds = [];
  },
  resetClassTypes(state) {
    state.classTypeIds = [];
  },
  setBookmarked(state, id) {
    const liveClass = state.liveClassCourse.videos.find((l) => l.id === id);
    liveClass.favorite = !liveClass.favorite;
  },
  setCourseDeactivated(state, id) {
    const liveClassCourse = state.liveClassCourses.find((l) => l.id === id);
    liveClassCourse.deactivated = !liveClassCourse.deactivated;
  },
  setDeactivated(state, id) {
    const liveClass = state.liveClassCourse.videos.find((v) => v.id === id);
    liveClass.deactivated = !liveClass.deactivated;
  },
  setHasLive(state, hasLive) {
    state.hasLive = hasLive;
  }
};

const getters = {
  classInstructorName(state) {
    return (instructor) => {
      return !instructor.subprofile
        ? `${instructor.first_name} ${instructor.last_name}`
        : `${instructor.first_name} & ${instructor.subprofile.first_name}`;
    };
  },
  weekVideos(state) {
    return state.liveClassCourse.videos.filter(
      (video) => video.week === state.weekNumber
    );
  }
};

export const classes = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
