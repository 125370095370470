<template>
  <div class="lock-icon">
    <i class="fas fa-lock"></i>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.lock-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  text-align: center;
  font-size: 13px;
}
</style>