<template>
  <div class="instructor-modal">
    <div
      v-if="showCloseButton"
      @click="$emit('close')"
      class="close"
    >
      <i class="fas fa-times"></i>
    </div>
    <h2 class="title">Instructor Profile</h2>
    <div class="main-block">
      <div class="photo-block">
        <base-avatar
          width="103px"
          height="103px"
          :url="instructor.photo"
        />
        <div class="name">
          {{ instructor.first_name }} {{ instructor.last_name }}
        </div>
      </div>
      <div
        class="photo-block subprofile"
        v-if="instructor.subprofile"
      >
        <base-avatar
          width="103px"
          height="103px"
          :url="instructor.subprofile.photo"
        />
        <div class="name">
          {{ instructor.subprofile.first_name }} {{ instructor.subprofile.last_name }}
        </div>
      </div>
    </div>
    <div
      class="bio-block"
      v-if="instructor.bio"
      v-html="bio"
    >
    </div>
  </div>
</template>

<script>
import { splitParagraphs } from '../../helpers/split-paragraphs';

export default {
  props: {
    instructor: {
      type: Object
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bio() {
      return splitParagraphs(this.instructor.bio);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'src/static/core/main.scss';

.instructor-modal {
  position: relative;
  background-color: #1f1532;
  padding: 50px;
  border-radius: 5px;
  box-sizing: border-box;
  .main-block {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
.title {
  font-family: $avenirheavy;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #d3acbe;
  margin: 0;
}
.name {
  font-family: $avenirheavy;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-top: 10px;
}
.photo-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.subprofile {
    margin-left: 150px;
  }
}
.bio-block {
  background-color: #382d4e;
  padding: 15px 30px;
  border-radius: 20px;
  margin-top: 30px;
  font-family: $avenirmedium;
  font-size: 15px;
}
.close {
  cursor: pointer;
  font-size: 25px;
}
@media only screen and (max-width: 600px) {
  .instructor-modal {
    padding: 20px;
  }
  .photo-block {
    &.subprofile {
      margin-left: 50px;
    }
  }
}
</style>