<template>
  <div class="search-dropdown">
    <div
      class="block-container"
      v-if="
        searchResult.recorded_classes.length ||
          searchResult.live_classes.length ||
          searchResult.tips.length
      "
    >
      <div
        class="block-wrapper"
        v-bar="{ preventParentScroll: true }"
      >
        <div class="search-result">
          <div
            class="search-block"
            v-if="searchResult.recorded_classes.length"
          >
            <h5 class="block-title">Classes</h5>
            <ul class="block-result">
              <search-item-summary
                v-for="(item, i) in searchResult.recorded_classes"
                :key="`recorded-${i}`"
                :thumbnail="
                  !item.moves_groups.length
                    ? item.thumbnail
                    : item.moves_groups[0].moves[0].thumbnail
                "
                :linkObject="{ name: 'class', params: { classId: item.id } }"
                :category="item.category.name"
                :title="item.title"
                :subtitle="
                  item.moves_groups.length
                    ? item.moves_groups[0].moves[0].title
                    : ''
                "
              />
            </ul>
          </div>
          <div
            class="search-block"
            v-if="searchResult.live_classes.length"
          >
            <h5 class="block-title">Live Classes</h5>
            <ul class="block-result">
              <search-item-summary
                v-for="(item, i) in searchResult.live_classes"
                :key="`live-class-${i}`"
                :thumbnail="
                  !item.videos.length ? item.image : item.videos[0].image
                "
                :linkObject="{
                  name: 'liveClassCourse',
                  params: { courseId: item.id },
                  query: linkQuery(item)
                }"
                :title="item.title"
                :subtitle="item.videos.length ? item.videos[0].title : ''"
              />
            </ul>
          </div>
          <div
            class="search-block"
            v-if="searchResult.tips.length"
          >
            <h5 class="block-title">Tips</h5>
            <ul class="block-result">
              <search-item-summary
                v-for="(item, i) in searchResult.tips"
                :key="`tip-${i}`"
                :thumbnail="item.thumbnail"
                :linkObject="{
                  name: 'tips'
                }"
                :title="item.title"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty"
    >
      Nothing was found on your search request
    </div>
  </div>
</template>

<script>
import SearchItemSummary from './SearchItemSummary';

export default {
  props: {
    searchResult: {
      type: Object,
      default: {}
    }
  },
  components: {
    SearchItemSummary
  },
  methods: {
    liveTitle(live) {
      return live.videos.length ? live.videos[0].title : live.title;
    },
    linkQuery(item) {
      let query = {
        search: 1,
      }

      if (item.videos[0]) {
        query.week = item.videos[0].week;
      }

      return query;
    }
  }
};
</script>

<style lang="scss" scoped>
.search-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  z-index: 1000;
  border-radius: 7px;
  background-color: #5c4773;
  box-sizing: border-box;
}
.block-container {
  height: 300px;
}
.block-wrapper {
  padding: 20px 0;
  box-sizing: border-box;
  height: 100%;
}
.search-result {
  padding: 0 20px;
  box-sizing: border-box;
}
.empty {
  font-family: 'Avenir Medium', sans-serif;
  font-size: 15px;
  padding: 20px;
}
.search-block {
  border-bottom: 1px solid #7c6d9a;
  padding: 15px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.block-title {
  margin-top: 0;
  margin-bottom: 9px;
  font-family: 'Avenir Heavy', sans-serif;
  font-size: 18px;
  line-height: 21px;
}
@media only screen and (max-width: 1110px) {
  .search-dropdown {
    width: 300px;
    right: -30px;
    top: 86px;
  }
}
</style>
