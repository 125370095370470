<template>
  <div class="select" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :name="name"
      :class="{ open: open }"
      @click="open = !open"
    >
      {{ selectedName }}
    </div>
    <div class="items" :class="{ hide: !open }">
      <div class="item empty" @click="reset" v-if="canReset"></div>
      <div
        :class="{ item: !option.section, section: option.section }"
        v-for="(option, i) in options"
        :key="i"
        @click="handleSelect(option)"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    value: {
      type: Object
    },
    name: {
      type: String,
      default: ''
    },
    canReset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: this.options.length > 0 && this.value ? this.value : null,
      open: false
    };
  },
  computed: {
    selectedName() {
      return this.selected ? this.selected.name : '';
    }
  },
  methods: {
    handleSelect(option) {
      if (!option.section) {
        this.open = false;
        this.$emit('input', option);
      }
    },
    reset() {
      this.open = false;
      this.$emit('input', null);
    }
  },
  watch: {
    value(val) {
      this.selected = val;
    }
  }
};
</script>
