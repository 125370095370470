<template>
  <div :class="['app', appClasses]">
    <div
      @click="back"
      class="back"
    ><i class="fas fa-arrow-left"></i></div>
    <div
      v-if="alert.message"
      :class="`alert ${alert.type}`"
    >
      <span>{{ alert.message }}</span>
      <div
        @click="closeAlert"
        class="close"
      >CLOSE</div>
    </div>
    <div class="version">{{ version }}</div>
    <router-view />
    <icon-sprite />
    <route-loader v-if="routeLoading" />
  </div>
</template>

<script>
import '@stripe/stripe-js'; // this import make Stripe.js  available everywhere
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import IconSprite from '../components/IconSprite';
import MobileWidthMixin from '../mixins/MobileWidthMixin';
import RouteLoader from '../components/RouteLoader';
import { VERSION } from "../helpers/constants";

export default {
  name: 'app',
  components: {
    IconSprite,
    RouteLoader
  },
  mixins: [MobileWidthMixin],
  mounted() {
    this.getDictionary();
    this.$nextTick(() => document.body.classList.add('app-mounted'));
  },
  created() {
  },
  data() {
    return {
      version: VERSION,
      websocketToken: null
    };
  },
  sockets: {
    has_live_class(has_live_class) {
      this.setHasLive(has_live_class);
    },
    subscription_updated() {
      //location.reload(true);
    },
    notification({ unread, notification }) {
      unread && this.setUnreadNotifications(unread);
      notification && this.addNewNotification(notification);
    }
  },
  computed: {
    ...mapGetters({
      isUser: 'account/isUser',
      isAdmin: 'account/isAdmin',
      isInstructor: 'account/isInstructor'
    }),
    ...mapState({
      alert: (state) => state.alert,
      user: (state) => state.account.user,
      routeLoading: (state) => state.ui.routeLoading
    }),
    appClasses() {
      return {
        'is-desktop': !this.isMobile,
        'is-mobile': this.isMobile,
        'is-user': this.isUser,
        'is-admin': this.isAdmin,
        'is-instructor': this.isInstructor
      };
    }
  },
  beforeDestroy() {
    this.user && this.$socket.client.close();
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      getDictionary: 'account/getDictionary',
      findLiveClassCourses: 'classes/findLiveClassCourses',
      getPaymentStatus: 'account/getPaymentStatus',
      getNotifications: 'account/getNotifications',
      getUnreadNotifications: 'account/getUnreadNotifications',
      websocketConnect: 'account/webSocketConnect'
    }),
    ...mapMutations({
      setUnreadNotifications: 'account/setUnreadNotifications',
      setHasLive: 'classes/setHasLive',
      addNewNotification: 'account/addNewNotification'
    }),
    closeAlert() {
      this.alert.message = null;
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.version {
  position: absolute;
  font-size: 15px;
  color: #7f6480;
  right: 10px;
  bottom: 10px;
}
</style>
