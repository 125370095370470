import Vue from 'vue';
import Vuex from 'vuex';

import { users } from './users.module';
import { alert } from './alert.module';
import { account } from './account.module';
import { animation } from './animation.module';
import { ui } from './ui.module';
import { classes } from './classes.module';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
  stripe: null
}

const mutations = {
  setStripe(state, stripe) {
    state.stripe = stripe;
  }
}

export const store = new Vuex.Store({
  state,
  mutations,
  modules: {
    users,
    alert,
    account,
    animation,
    ui,
    classes
  },
  plugins: [createPersistedState()],
});
