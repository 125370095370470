import {
  handleResponse,
  preparePostRequest,
  prepareGetRequest
} from './main.service';
import {authHeader, sendRequest, sendGetRequest, sendPostRequest} from '../helpers';

export const usersService = {
  create,
  update,
  deactivateOrActivate,
  getAll,
  getInstructors,
  get,
  delete: _delete
};

function create(
  firstName,
  lastName,
  role,
  email,
  password,
  photo,
  firstNameSubprofile,
  lastNameSubprofile,
  photoSubprofile,
  bio = null
) {
  let formData = new FormData();
  if (firstName) {
    formData.append('first_name', firstName);
  }
  if (lastName) {
    formData.append('last_name', lastName);
  }
  if (role) {
    formData.append('role', role);
  }
  if (email) {
    formData.append('email', email);
  }
  if (password) {
    formData.append('password', password);
  }
  if (photo) {
    formData.append('photo', photo);
  }
  if (firstNameSubprofile) {
    formData.append('subprofile_first_name', firstNameSubprofile);
  }
  if (lastNameSubprofile) {
    formData.append('subprofile_last_name', lastNameSubprofile);
  }
  if (photoSubprofile) {
    formData.append('subprofile_photo', photoSubprofile);
  }
  if (bio) {
    formData.append('bio', bio);
  }

  const requestOptions = {
    method: 'post',
    headers: { ...authHeader() },
    body: formData
  };

  return sendRequest(
    `${process.env.API_URL}/users/invite`,
    requestOptions
  ).then(handleResponse);
}

function update(body, userId) {
  return sendRequest(
    `${process.env.API_URL}/users/${userId}`,
    preparePostRequest(body)
  ).then(handleResponse);
}

function deactivateOrActivate(id) {
  let formData = new FormData();

  const requestOptions = {
    method: 'post',
    headers: { ...authHeader() },
    body: formData
  };

  return sendRequest(
    `${process.env.API_URL}/users/${id}/deactivate-or-activate`,
    requestOptions
  ).then(handleResponse);
}

function get(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return sendRequest(`${process.env.API_URL}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return sendRequest(`${process.env.API_URL}/users/${id}`, requestOptions).then(
    handleResponse
  );
}

function getAll(page = 1, params = {}) {
  return sendGetRequest(`${process.env.API_URL}/users?page=${page}`, params)
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.log(error));
}

function getInstructors(params = {}) {
  return sendGetRequest(
    `${process.env.API_URL}/users/get-instructors?page=1`,
    params
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.log(error));
}
