<template>
  <router-link :to="linkObject" class="summary">
    <div
      class="thumbnail"
      :style="{ backgroundImage: `url(${thumbnail})` }"
    ></div>
    <div class="info">
      <div class="item-category" v-if="category">{{ category }}</div>
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    thumbnail: {
      type: String
    },
    linkObject: {
      type: Object
    },
    category: {
      type: String
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.summary {
  display: flex;
  color: #ffffff;
  margin-bottom: 15px;
}
.thumbnail {
  width: 57px;
  height: 57px;
  margin-right: 20px;
  border-radius: 8px;
  background-size: cover;
  background-position: 50% 50%;
  flex-shrink: 0;
}
.item-category {
  font-family: 'Helvetica', sans-serif;
  font-size: 13px;
  margin-bottom: 5px;
  text-align: left;
}
.title {
  font-family: 'Avenir Heavy', sans-serif;
  font-size: 15px;
  margin-bottom: 3px;
}
.subtitle {
  font-family: 'Avenir Medium', sans-serif;
  font-size: 13px;
}
</style>
