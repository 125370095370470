import {authHeader, router, sendRequest, validateFields} from '../helpers';
import snakeCase from 'lodash/snakeCase';

export function logoutBase() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    localStorage.removeItem('user');
    localStorage.removeItem('payment-status');

    return sendRequest(`${process.env.API_URL}/auth/logout`, requestOptions).then(
        handleResponse
    );
}

export function handleResponse(response) {
    return response.text().then((text) => {
        //UNBLOCK BUTTONS
        let buttons = document.querySelectorAll('button');
        buttons.forEach(function (button) {
            button.disabled = false;
        });
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401 && !router.currentRoute.path.startsWith('auth')) {
                router.push({name: 'login'});
            }
            // else if (response.status === 403) {
            //     router.push({name: 'forbidden'})
            // }
            if (data.errors) {
                validateFields(data.errors);

                return Promise.reject('');
            } else {
                const error = data && data.message;

                return Promise.reject(error);
            }
        }

        return data;
    });
}

export function preparePostRequest(body = {}) {
    let formData = new FormData();
    for (let key in body) {
        if (body[key] !== null && body[key] !== undefined) {
            if(Array.isArray(body[key])) {
                formData.append(snakeCase(key), JSON.stringify(body[key]));
            } else {
                formData.append(snakeCase(key), body[key]);
            }
        }
    }
    let obj = {
        method: 'POST',
        headers: {...authHeader()},
        body: formData
    };

    return obj;
}

export function preparePutRequest(body = {}) {
    let formData = new FormData();
    for (let key in body) {
        if (body[key] !== null && body[key] !== undefined) {
            formData.append(snakeCase(key), body[key]);
        }
    }

    return {
        method: 'PUT',
        headers: {...authHeader()},
        body: formData
    };
}

export function prepareGetRequest(params = {}) {
    return {
        method: 'GET',
        headers: authHeader(),
        qs: params
    };
}
