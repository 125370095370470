var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-dropdown"},[(
      _vm.searchResult.recorded_classes.length ||
        _vm.searchResult.live_classes.length ||
        _vm.searchResult.tips.length
    )?_c('div',{staticClass:"block-container"},[_c('div',{directives:[{name:"bar",rawName:"v-bar",value:({ preventParentScroll: true }),expression:"{ preventParentScroll: true }"}],staticClass:"block-wrapper"},[_c('div',{staticClass:"search-result"},[(_vm.searchResult.recorded_classes.length)?_c('div',{staticClass:"search-block"},[_c('h5',{staticClass:"block-title"},[_vm._v("Classes")]),_vm._v(" "),_c('ul',{staticClass:"block-result"},_vm._l((_vm.searchResult.recorded_classes),function(item,i){return _c('search-item-summary',{key:("recorded-" + i),attrs:{"thumbnail":!item.moves_groups.length
                  ? item.thumbnail
                  : item.moves_groups[0].moves[0].thumbnail,"linkObject":{ name: 'class', params: { classId: item.id } },"category":item.category.name,"title":item.title,"subtitle":item.moves_groups.length
                  ? item.moves_groups[0].moves[0].title
                  : ''}})}),1)]):_vm._e(),_vm._v(" "),(_vm.searchResult.live_classes.length)?_c('div',{staticClass:"search-block"},[_c('h5',{staticClass:"block-title"},[_vm._v("Live Classes")]),_vm._v(" "),_c('ul',{staticClass:"block-result"},_vm._l((_vm.searchResult.live_classes),function(item,i){return _c('search-item-summary',{key:("live-class-" + i),attrs:{"thumbnail":!item.videos.length ? item.image : item.videos[0].image,"linkObject":{
                name: 'liveClassCourse',
                params: { courseId: item.id },
                query: _vm.linkQuery(item)
              },"title":item.title,"subtitle":item.videos.length ? item.videos[0].title : ''}})}),1)]):_vm._e(),_vm._v(" "),(_vm.searchResult.tips.length)?_c('div',{staticClass:"search-block"},[_c('h5',{staticClass:"block-title"},[_vm._v("Tips")]),_vm._v(" "),_c('ul',{staticClass:"block-result"},_vm._l((_vm.searchResult.tips),function(item,i){return _c('search-item-summary',{key:("tip-" + i),attrs:{"thumbnail":item.thumbnail,"linkObject":{
                name: 'tips'
              },"title":item.title}})}),1)]):_vm._e()])])]):_c('div',{staticClass:"empty"},[_vm._v("\n    Nothing was found on your search request\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }