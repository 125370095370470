require('./bootstrap');

import './static/app.scss';
import '@fortawesome/fontawesome-free/js/all.js';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VModal from 'vue-js-modal';
import { ServerTable, ClientTable, Event } from 'vue-tables-2';
import Vue from 'vue';
import { router } from './helpers';
import { store } from './store';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import DragAndDrop from 'vue-drag-and-drop';
import VueDragscroll from 'vue-dragscroll/dist/vue-dragscroll';
import App from './app/App';
import Paginate from 'vuejs-paginate';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import VTooltip from 'v-tooltip';
import VueChatScroll from 'vue-chat-scroll';
import Vuebar from 'vuebar';
import VueCountdown from '@chenfengyuan/vue-countdown';
import * as firebase from "firebase/app";
import "firebase/analytics";
import { VERSION } from "./helpers/constants";
import VueGtm from "vue-gtm";

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.use(VueDragscroll);
Vue.use(DragAndDrop);
Vue.use(VTooltip);
Vue.use(VueChatScroll);
Vue.use(Vuebar);

Vue.component('paginate', Paginate);

//init sentry log
const SENTRY_LOCAL_ENV = 'local';
if (process.env.SENTRY_ENVIRONMENT !== SENTRY_LOCAL_ENV) {
  Sentry.init({
    dsn: process.env.SENTRY_LARAVEL_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: process.env.SENTRY_ENVIRONMENT
  });
  Sentry.setTag("app_version", VERSION);
}

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

//activate modals
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
//init tables
Vue.use(ClientTable, {}, false, 'bootstrap3', 'footerPagination');
window.Event = Event;
//init components
Vue.component('navbar', require('./components/main/Navbar.vue').default);
Vue.component(
  'sidebar',
  require('./components/main-admin/Sidebar.vue').default
);
Vue.component(
  'mobile-menu',
  require('./components/main/MobileMenu.vue').default
);
Vue.component(VueCountdown.name, VueCountdown);

//init base components by requireComponent
const requireComponent = require.context(
  './components/base',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

// config socket connection
const socket = io(process.env.SOCKET_URL, {
  autoConnect: false,
  secure: true
});

Vue.use(VueSocketIOExt, socket, { store });

Vue.directive('inputfilter', {
  bind: function(el, binding) {
    switch (binding.value) {
      case 'name':
        el.addEventListener('keypress', (e) => {
          const re = new RegExp("[A-Za-z.\\s-']");
          if (!re.test(e.key)) {
            e.preventDefault();
          }
        });
        break;

      case 'positive-integer':
        el.addEventListener('keypress', (e) => {
          const re = new RegExp('[0-9]');
          if (!re.test(e.key)) {
            e.preventDefault();
          }
        });
        break;

      case 'zip-input':
        el.addEventListener('keypress', (e) => {
          const re = new RegExp('[0-9A-Za-z]');
          if (!re.test(e.key)) {
            e.preventDefault();
          }
        });
        break;

      default:
        el.addEventListener('keypress', (e) => {
          const re = new RegExp("[A-Za-z.\\s']");
          if (!re.test(e.key)) {
            e.preventDefault();
          }
        });
    }
  }
});

Vue.use(VueGtm, {
  id: "GTM-55VCHQN", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  /*queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
    gtm_preview: "env-4",
    gtm_cookies_win: "x",
  },*/
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
