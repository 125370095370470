<template>
  <div class="navbar">
    <div class="navbar__left">
      <router-link :to="{ name: isAdmin ? 'adminClassesHome' : 'classesHome' }">
        <img src="/img/icons/logo.svg" class="navbar__logo" />
      </router-link>
      <ul class="nav__menu" v-if="roleId !== 1">
        <li>
          <router-link :to="{ name: 'classesHome' }" class="nav__link">Classes</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'tips' }" class="nav__link">Tips</router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: isUser ? 'liveClassCourses' : 'adminLiveClassCourses',
            }"
            class="nav__link"
            >Live</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'bookmarks' }" class="nav__link">Bookmarks</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'feed' }" class="nav__link">KNKT</router-link>
        </li>
      </ul>
    </div>
    <div class="navbar__right">
      <div
        class="search__container"
        v-if="!isAdmin"
        v-on-clickaway="closeSearchResult"
      >
        <img
          src="/img/icons/search-pink.svg"
          class="search__button"
          @click="showSearch = !showSearch"
          role="button"
        />
        <div class="navbar__search">
          <form
            class="search__input__group full-screen"
            @submit.prevent="onSearchSubmit"
          >
            <img src="/img/icons/search.svg" />
            <input
              type="text"
              class="search__input"
              placeholder="Search"
              @focus="onSearchFocus"
              v-model="searchQuery"
            />
          </form>
          <transition name="quick-fade">
            <div class="select__container" v-show="showSearch">
              <form
                class="search__input__group"
                @submit.prevent="onSearchSubmit"
              >
                <img src="/img/icons/search.svg" />
                <input
                  type="text"
                  class="search__input"
                  placeholder="Search"
                  @focus="onSearchFocus"
                  v-model="searchQuery"
                />
              </form>
            </div>
          </transition>
          <transition name="quick-fade">
            <search-dropdown
              v-if="showSearchResult"
              :searchResult="searchResult"
            />
          </transition>
        </div>
      </div>
      <div class="navbar__ring" @click="openNotifications">
        <img src="/img/icons/ring.svg" />
        <div class="new" v-if="unreadNotifications">
          {{ unreadNotifications }}
        </div>
      </div>
      <transition name="quick-fade">
        <notifications
          v-if="showNotifications"
          v-on-clickaway="closeNotifications"
        />
      </transition>
      <div class="navbar__wrap" @click.stop="openMenu">
        <base-avatar :url="photo" />
      </div>
      <div @click="openMenu" class="navbar__profile">
        <div class="user__name">{{ fullName }}</div>
        <i class="arrow down"></i>
        <ul class="dropdown-menu" v-show="showMenu" :class="{ open: showMenu }">
          <li class="item">
            <router-link :to="{ name: profileLink }" class="item__link">
              Settings
            </router-link>
          </li>
          <li class="item" v-if="isUser">
            <router-link :to="{ name: 'contact' }" class="item__link">
              Help
            </router-link>
          </li>
          <li @click.prevent="handleLogout" class="item">
            <a href="#" class="item__link"> Sign Out </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { mapState, mapGetters, mapActions } from 'vuex';
import { sendGetRequest } from '../../helpers';
import SearchDropdown from '../main/search/SearchDropdown';
const Notifications = () => import('./notifications/Notifications');

export default {
  components: {
    Notifications,
    SearchDropdown,
  },
  directives: {
    onClickaway,
  },
  data() {
    return {
      showMenu: false,
      showSearch: false,
      showNotifications: false,
      searchQuery: null,
      searchResult: null,
      showSearchResult: false,
    };
  },
  computed: {
    ...mapState({
      photo: (state) => state.account.user.photo,
      roleId: (state) => state.account.user.role_id,
      unreadNotifications: (state) => state.account.unreadNotifications,
    }),
    ...mapGetters({
      fullName: 'account/fullName',
      isUser: 'account/isUser',
      isAdmin: 'account/isAdmin',
    }),
    profileLink() {
      switch (this.roleId) {
        case 1:
          return 'profile';
        case 2:
          return 'userProfile';
        case 3:
          return 'instructorProfile';
        default:
          return 'userProfile';
      }
    },
  },
  watch: {
    $route: function () {
      this.showSearchResult = false;
      this.showSearch = false;
    },
  },
  created() {
    let curObj = this;
    document.querySelector('body').onclick = function (e) {
      if (e.target.closest('.navbar__profile') === null) {
        curObj.showMenu = false;
      }
    };
  },
  methods: {
    ...mapActions('account', ['logout']),
    openMenu() {
      this.showMenu = !this.showMenu;
    },
    handleLogout() {
      this.$router.push({ name: 'login' });
      this.logout();
    },
    openNotifications() {
      this.showNotifications = true;
    },
    closeNotifications() {
      this.showNotifications = false;
    },
    onSearchSubmit() {
      if (this.searchQuery) {
        fbq('track', 'Search', {search_string: this.searchQuery});
        sendGetRequest(`${process.env.API_URL}/search`, {
          text: this.searchQuery,
        }).then((response) => {
          this.searchResult = response.data.result;
          this.showSearchResult = true;
        });
      }
    },
    onSearchFocus() {
      if (this.searchQuery && this.searchResult) {
        this.showSearchResult = true;
      }
    },
    closeSearchResult() {
      this.showSearchResult = false;
      if (this.showSearch) {
        this.showSearch = false;
      }
    },
  },
};
</script>
