<template>
  <div class="instructor-rating">
    <base-avatar
      v-if="!instructor.subprofile"
      :url="instructor.photo"
      :width=width
      :height=height
    />
    <base-double-avatar
      v-else
      :photo="instructor.photo"
      :photoSub="instructor.subprofile.photo"
      :width=width
      :height=height
    />

    <div class="rating-info">
      <div
        class="user-name"
        @click.stop="openModal"
      >
        {{ classInstructorName(instructor) }}
      </div>

      <div
        class="class-progress"
        v-if="currentWeek"
      >
        Currently on <span>Week {{ currentWeek }}</span>
      </div>
      <div
        class="rating"
        v-else-if="past && reviews && isAdmin"
      >
        <star-rating
          :increment="1"
          :max-rating="5"
          :rating="reviews.rating"
          inactive-color="#000"
          active-color="#F5A623"
          :border-width="2"
          border-color="#F5A623"
          :star-size="13"
          :show-rating="false"
          :read-only="true"
        />
        <span class="reviews">
          <template v-if="date">{{ readableDate }}</template>
          <template v-else>{{ reviews.rating }}</template>

          <template v-if="reviews.count !== null && reviews.count !== undefined">
            (<span
              :class="{ 'reviews-count': !isUser }"
              @click.stop="onClickReviews"
            >
              {{ reviews.count }} Reviews </span>)
          </template>
        </span>
      </div>
      <!-- <div
        class="class-progress"
        v-else
      >
        No rating
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import StarRating from 'vue-star-rating';
import InstructorProfileModal from '../common/InstructorProfileModal';

export default {
  props: {
    reviews: {
      type: Object
    },
    currentWeek: {
      type: Number,
      default: null
    },
    current: {
      type: Boolean,
      default: false
    },
    past: {
      type: Boolean,
      default: false
    },
    instructor: {
      type: Object,
      default: () => {
        return {};
      }
    },
    width: {
      type: String,
      default: '42px'
    },
    height: {
      type: String,
      default: '42px'
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    date: { type: String, default: '' }
  },
  components: {
    InstructorProfileModal,
    StarRating
  },
  computed: {
    ...mapGetters({
      classInstructorName: 'classes/classInstructorName',
      isUser: 'account/isUser',
      isAdmin: 'account/isAdmin'
    }),
    readableDate() {
      if (!this.date) return '';
      return moment(this.date).fromNow();
    }
    // instructorModalWidth() {
    //   if (window.innerWidth < 900) {
    //     return this.instructor.subprofile ? 900 : 508;
    //   } else {
    //     return '100%';
    //   }
    // }
  },
  methods: {
    onClickReviews() {
      this.$emit('clickReviews');
    },
    openModal() {
      this.$modal.show(
        InstructorProfileModal,
        {
          instructor: this.instructor,
          showCloseButton: this.isMobile
        },
        {
          width: this.instructor.subprofile ? 900 : 508,
          adaptive: true,
          height: 'auto',
          classes: 'modal-profile',
          scrollable: true
        }
      );
    }
  }
};
</script>

<style scoped>
.reviews-count {
  cursor: pointer;
}
</style>
